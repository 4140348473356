export const appBaseURL = "/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=375, user-scalable=no"},{"hid":"description","name":"description","content":"B.LEAGUE CARD ~WEB~"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico","sizes":"48x48"},{"rel":"apple-touch-icon","type":"image/x-icon","href":"/icons/apple-touch-icon-180x180.png","sizes":"180x180"},{"key":"mdi","rel":"stylesheet","href":"https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css","type":"text/css","crossorigin":"anonymous"}],"style":[],"script":[{"async":true,"src":"https://www.googletagmanager.com/gtag/js?id=G-K8MW80FQFC"},{"hid":"gascript","innerHTML":"window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-K8MW80FQFC');"}],"noscript":[],"title":"B.LEAGUE CARD ~WEB~","htmlAttrs":{"lang":"ja"},"base":{"href":"router.base"},"__dangerouslyDisableSanitizersByTagID":{"gascript":["innerHTML"]}}

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'