import validate from "/var/emtg/card.bleaguepark.jp/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45redirect_45global from "/var/emtg/card.bleaguepark.jp/middleware/01_redirect.global.js";
import _02_45check_45version_45global from "/var/emtg/card.bleaguepark.jp/middleware/02_check_version.global.js";
import manifest_45route_45rule from "/var/emtg/card.bleaguepark.jp/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45redirect_45global,
  _02_45check_45version_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}