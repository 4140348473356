import DOMPurify from 'dompurify';

// カスタマイズが必要な場合はここで指定。xss対策としてはデフォルトで良いはず
const domPurifyOption: DOMPurify.Config = {
  FORCE_BODY: true,
};

export default defineNuxtPlugin(nuxtApp => {
  return {
    provide: {
      sanitize: (html: string) => DOMPurify.sanitize(html, domPurifyOption)
    }
  }
})
