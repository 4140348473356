/** userAgent判別クラス
 */
export class userAgent{
  constructor(){
    this.userAgent = process.server
      ? context.req.headers['user-agent']
      : navigator.userAgent;

    this.standalone = navigator.standalone;

    this.agent = "Unknown";
    const agent = window.navigator.userAgent.toLowerCase();
    
    if (agent.indexOf("msie") != -1 || agent.indexOf("trident") != -1) {
      this.agent = "IE";
    } else if (agent.indexOf("edg") != -1 || agent.indexOf("edge") != -1) {
      this.agent = "Edge";
    } else if (agent.indexOf("opr") != -1 || agent.indexOf("opera") != -1) {
      this.agent = "Opera";
    } else if (agent.indexOf("chrome") != -1) {
      this.agent = "Chrome";
    } else if (agent.indexOf("safari") != -1) {
      this.agent = "Safari";
    } else if (agent.indexOf("firefox") != -1) {
      this.agent = "Firefox";
    }
  }

  /**　CCのアプリかどうか判定
   * @returns true= アプリ
   */
  isApp = ()=>{
    return this.userAgent.includes('X-APP-VERSION');
  };

  /**　スタンドアローン起動しているかどうか
   * @returns true= スタンドアローン
   */
  isStandAlone = ()=>{

    return this.standalone;
  }

  /**　IOSどうか判定
   * @returns true= IOS
   */
  isIOS = ()=>{
    return  /iP(hone|(o|a)d)/.test(this.userAgent)
  }

  /**　Safariどうか判定
   * @returns true= Safari
   */
  isSafari = ()=>{
    return  this.agent.includes("Safari");
  }

  /**　スタンドアローン起動しているかどうか
   * @returns true= スタンドアローン
   */
  isStandAlone = ()=>{

    return navigator.standalone;
  }

  /**
   * ユーザーエージェント文字列
   * @type {string}
   */
  userAgent = "";
}

export default defineNuxtPlugin(nuxtApp => {
  return {
    provide: {
      ua: new userAgent()
    }
  }
})
