import revive_payload_client_4sVQNw7RlN from "/var/emtg/card.bleaguepark.jp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/emtg/card.bleaguepark.jp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/emtg/card.bleaguepark.jp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/emtg/card.bleaguepark.jp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/var/emtg/card.bleaguepark.jp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/emtg/card.bleaguepark.jp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_Eorgdr12jA from "/var/emtg/card.bleaguepark.jp/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import vuetify_icons_RMzWu406ID from "/var/emtg/card.bleaguepark.jp/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import vuetify_no_client_hints_wMKVLl9fyU from "/var/emtg/card.bleaguepark.jp/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import chunk_reload_client_UciE0i6zes from "/var/emtg/card.bleaguepark.jp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/emtg/card.bleaguepark.jp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import execute_1SRdA39dgH from "/var/emtg/card.bleaguepark.jp/plugins/execute.js";
import jquery_SqE5xeO2Mp from "/var/emtg/card.bleaguepark.jp/plugins/jquery.js";
import index_8tYvix1hLx from "/var/emtg/card.bleaguepark.jp/plugins/openapi/index.ts";
import sanitize_fbXCtPd0F2 from "/var/emtg/card.bleaguepark.jp/plugins/sanitize.ts";
import ua_Ig5SPdmVaR from "/var/emtg/card.bleaguepark.jp/plugins/ua.js";
import underscore_jNbdJQZPbl from "/var/emtg/card.bleaguepark.jp/plugins/underscore.js";
import utils_EeQrN1BEbD from "/var/emtg/card.bleaguepark.jp/plugins/utils.js";
import vuetify_OdPEdOST6f from "/var/emtg/card.bleaguepark.jp/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_client_Eorgdr12jA,
  vuetify_icons_RMzWu406ID,
  vuetify_no_client_hints_wMKVLl9fyU,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  execute_1SRdA39dgH,
  jquery_SqE5xeO2Mp,
  index_8tYvix1hLx,
  sanitize_fbXCtPd0F2,
  ua_Ig5SPdmVaR,
  underscore_jNbdJQZPbl,
  utils_EeQrN1BEbD,
  vuetify_OdPEdOST6f
]