import { cf_app_loadImageApi_proxy, cf_app_loadImageSetOnLoadComplate } from '@/utils/modules/sp/client_functions-module';
import { userAgent } from './ua';

const ua = new userAgent();

const cf_store_payment = (onReturnCallback, productId) => {
  console.log("購入処理");
};

const test = (a, b) => {
  console.log(a, b);
};

const redirect = (res) => {
  console.log(res);
  if (res.data.status == 401) {
    window.location.href =
      "";
  }
};

var ImageCacheLoader = (config) => {
  let m_cards_state;
  return {
    m_load_image_list: [],
    set_m_cards_state: function (_m_cards_state) {
      m_cards_state = _m_cards_state;
    },
    get_front_card_cache_key: function (row, is_thumb) {
      var key = "front_card_";
      if (is_thumb) key += "thumb_";
      key += row["id"];
      return key;
    },
    get_back_card_cache_key: function (row, is_thumb) {
      var key = "back_card_";
      let img_url;
      if (is_thumb) key += "thumb_";
      if (row["back_image_key"]) {
        key += "b_" + row["id"];
        img_url =
          m_cards_state.card_base_uri +
          m_cards_state.card_back_path +
          row["created_date"] +
          "/" +
          row["back_image_key"] +
          "/" +
          row["id"];
      } else {
        if (!row["pd_image_key"]) return "";
        key += "p_" + row["pd_id"];
      }
      return key;
    },
    isAndroid: function () {
      return navigator.userAgent.indexOf("Android") != -1;
    },
    isManualApngDevice: function () {
      return navigator.userAgent.indexOf("iOS") != -1; // iphone 5cを対象にしたいけど、わからないから IOS 10系全部
    },
    startApng: function (id, base_id) {
      if (!this.isManualApngDevice()) return;

      var target = $("#" + id);
      var base_rect = $("#" + base_id);
      target.width(base_rect[0].width);
      target.height(base_rect[0].height);
      APNG.animateImage(target[0]);
    },
    endApng: function (id) {
      if (!this.isManualApngDevice()) return;

      var target = $("#" + id);
      return APNG.releaseCanvas(target[0]);
    },
    get_front_card_image_url: function (row, is_thumb) {
      let img_url;
      if (!row["image_key"]) return "";
      img_url =
        m_cards_state.card_base_uri +
        m_cards_state.card_front_path +
        row["created_date"] +
        "/" +
        row["image_key"] +
        "/" +
        row["id"];
      if (row["video_flg"] == 1) {
        if (is_thumb) {
          img_url += ".gif";
        } else {
          if (this.isAndroid()) {
            img_url += ".webp";
          } else {
            img_url += ".png";
          }
        }
      } else {
        if (is_thumb) img_url += m_cards_state.thumb_file_suffix;
        img_url += ".jpg";
      }
      return img_url;
    },
    get_back_card_image_url: function (row, is_thumb) {
      let img_url;
      if (row["back_image_key"]) {
        img_url =
          m_cards_state.card_base_uri +
          m_cards_state.card_back_path +
          row["created_date"] +
          "/" +
          row["back_image_key"] +
          "/" +
          row["id"];
      } else {
        if (!row["pd_image_key"]) return "";
        img_url =
          m_cards_state.card_base_uri +
          m_cards_state.card_personal_path +
          row["pd_created_date"] +
          "/" +
          row["pd_image_key"] +
          "/" +
          row["pd_id"];
      }
      if (is_thumb) img_url += m_cards_state.thumb_file_suffix;
      img_url += ".jpg";
      return img_url;
    },
    create_card_image: function (
      card_data,
      is_thumb,
      class_name,
      invalid_card_img_path,
      img_id,
      load_list_name
    ) {
      if (typeof load_list_name === "undefined") load_list_name = "main";
      if (typeof img_id === "undefined") img_id = this.get_front_card_cache_key(card_data, is_thumb);
      if (!this.m_load_image_list[load_list_name]) this.m_load_image_list[load_list_name] = [];

      const img = document.createElement("img");
      img.id = img_id;
      img.className = class_name;
      if (m_cards_state) {
        img.src = this.get_front_card_image_url(card_data, false);
      } else {
        img.src = `${config.public.ROUTER_BASE}/sp/img/layout/card_nothave.png`;
      }

      if (typeof card_data.image_key != "undefined") {
        this.m_load_image_list[load_list_name].push({
          type: "front_card",
          img_id: img_id,
          data: card_data,
          is_thumb: is_thumb,
        });
      }

      return img;
    },
    create_card_image_string: function (
      card_data,
      is_thumb,
      class_name,
      invalid_card_img_path,
      img_id,
      load_list_name
    ) {
      if (typeof load_list_name === "undefined") load_list_name = "main";
      if (typeof img_id === "undefined") img_id = this.get_front_card_cache_key(card_data, is_thumb);

      if (!this.m_load_image_list[load_list_name]) this.m_load_image_list[load_list_name] = [];

      const img = {
        id: img_id,
        class: class_name,
        src: `${config.public.ROUTER_BASE}/sp/img/layout/card_nothave.png`,
      };

      if (typeof card_data.image_key != "undefined") {
        this.m_load_image_list[load_list_name].push({
          type: "front_card",
          img_id: img_id,
          data: card_data,
          is_thumb: is_thumb,
        });
      }

      return `<img id="${img.id}" class="${img.class}" src="${img.src}">`;
    },
    load_card_image: function (card_data, is_thumb, img_id, load_list_name) {
      if (card_data === undefined) return;
      if (typeof load_list_name === "undefined") load_list_name = "main";
      if (typeof img_id === "undefined") img_id = "";
      if (!this.m_load_image_list[load_list_name])
        this.m_load_image_list[load_list_name] = [];
      this.m_load_image_list[load_list_name].push({
        type: "front_card",
        img_id: img_id,
        data: card_data,
        is_thumb: is_thumb,
      });
    },
    create_back_card_image: function (
      card_data,
      class_name,
      invalid_card_img_path,
      img_id,
      load_list_name
    ) {
      if (typeof load_list_name === "undefined") load_list_name = "main";
      if (typeof img_id === "undefined")
        img_id = this.get_back_card_cache_key(card_data, false);
      if (!this.m_load_image_list[load_list_name])
        this.m_load_image_list[load_list_name] = [];
      var img = document.createElement("img");
      img.id = img_id;
      img.className = class_name;
      if (isset(card_data.image_key)) {
        img.src = `${config.public.ROUTER_BASE}/sp/img/layout/card_nothave.png`;
        this.m_load_image_list[load_list_name].push({
          type: "back_card",
          img_id: img_id,
          data: card_data,
          is_thumb: false,
        });
      } else {
        img.src = invalid_card_img_path;
      }

      return img;
    },
    load_back_card_image: function (card_data, img_id, load_list_name) {
      if (card_data === undefined) return;
      if (typeof load_list_name === "undefined") load_list_name = "main";
      if (typeof img_id === "undefined") img_id = "";
      if (!this.m_load_image_list[load_list_name])
        this.m_load_image_list[load_list_name] = [];
      this.m_load_image_list[load_list_name].push({
        type: "back_card",
        img_id: img_id,
        data: card_data,
        is_thumb: false,
      });
    },
    // blankで表示しといて、ロード
    load_assets_image: function (id, key, update_key, url, load_list_name) {
      if (typeof load_list_name === "undefined") load_list_name = "main";
      if (!this.m_load_image_list[load_list_name])
        this.m_load_image_list[load_list_name] = [];
      this.m_load_image_list[load_list_name].push({
        type: "assets",
        data: {
          id: id,
          key: key,
          contents_type: url.split(".").pop(),
          update_key: update_key,
          url: url,
        },
        is_thumb: false,
      });
    },
    start_load_image: function (load_list_name) {
      if (typeof load_list_name === "undefined") load_list_name = "main";
      if (!this.m_load_image_list[load_list_name]) return;
      var list = this.m_load_image_list[load_list_name];
      for (var i = 0; i < list.length; i++) {
        if (list[i].type == "front_card") {
          this.load_front_card_cache(
            list[i].data,
            list[i].is_thumb,
            list[i].img_id,
            load_list_name
          );
        } else if (list[i].type == "back_card") {
          this.load_back_card_cache(
            list[i].data,
            list[i].is_thumb,
            list[i].img_id,
            load_list_name
          );
        } else if (list[i].type == "assets") {
          this.load_assets_cache(list[i].data, load_list_name);
        }
      }
      this.m_load_image_list[load_list_name] = [];
    },
    get_load_image_count: function (load_list_name) {
      if (typeof load_list_name === "undefined") load_list_name = "main";
      if (!this.m_load_image_list[load_list_name]) return 0;
      var list = this.m_load_image_list[load_list_name];
      return list.length;
    },
    set_onload_complate: function (req_count, timeout, fn_comp, load_list_name) {
      if (typeof load_list_name === "undefined") load_list_name = "main";
      cf_app_loadImageSetOnLoadComplate(
        req_count,
        timeout,
        fn_comp,
        load_list_name
      );
    },
    load_front_card_cache: function (row, is_thumb, img_id, load_list_name) {
      if (row === undefined) return "";
      if (typeof load_list_name === "undefined") load_list_name = "main";
      if (!row["image_key"]) return "";
      var img_url = this.get_front_card_image_url(row, is_thumb);
      var key = this.get_front_card_cache_key(row, is_thumb);
      var id = "#" + key;
      if (img_id) id = "#" + img_id;
      var contents_type = "jpg";
      var update_key = row["image_key"];

      cf_app_loadImageApi_proxy(
        ua,
        id,
        key,
        contents_type,
        update_key,
        img_url,
        load_list_name,
      );
    },
    load_back_card_cache: function (row, is_thumb, img_id, load_list_name) {
      if (typeof load_list_name === "undefined") load_list_name = "main";
      if (!row["back_image_key"] && !row["pd_image_key"]) return "";
      var img_url = this.get_back_card_image_url(row, is_thumb);
      var key = this.get_back_card_cache_key(row, is_thumb);
      var id = "#back_card_" + row["id"];
      if (img_id) id = "#" + img_id;
      var contents_type = "jpg";
      var update_key = "";
      if (row["back_image_key"]) {
        update_key = "b_" + row["back_image_key"];
      } else if (row["pd_image_key"]) {
        update_key = "p_" + row["pd_image_key"];
      }

      cf_app_loadImageApi_proxy(
        ua,
        id,
        key,
        contents_type,
        update_key,
        img_url,
        load_list_name,
      );
    },
    load_assets_cache: function (data, load_list_name) {
      if (typeof load_list_name === "undefined") load_list_name = "main";
      var img_url = data.url;
      var key = data.key;
      var id = data.id;
      var contents_type = data.contents_type;
      var update_key = data.update_key;

      cf_app_loadImageApi_proxy(
        ua,
        id,
        key,
        contents_type,
        update_key,
        img_url,
        load_list_name,
      );
    },
  };
}

var GIANTS = {
  setLang: function (settins) {
    this.lang = settins;
  },
  l: function (f, v) {
    return this.lang[f][v];
  },
  log: function (msg) {
    console.log(msg);
  },
  setController: function (name, controller) {
    if (!this.controllers) this.controllers = {};
    this.controllers[name] = controller;
    if (typeof this.controllers[name].init == "function") {
      this.controllers[name].init();
    }
  },
  sprintf: function () {
    var format = arguments[0];
    var args = arguments;
    var i = 1;
    return format.replace(/%s/g, function () {
      return args[i++];
    });
  },
  date: function () {
    var d = new Date();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    return d.getFullYear() + "/" + month + "/" + day;
  },
  getQuerystring: function (key, default_) {
    if (default_ == null) default_ = "";
    key = key.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + key + "=([^&#]*)");
    var qs = regex.exec(window.location.href);
    if (qs == null) {
      return default_;
    } else {
      return qs[1];
    }
  },
  hashLength: function (array) {
    var len = 0;
    for (var key in array) {
      len++;
    }
    return len;
  },
  getPath: function () {
    var parts = window.location.pathname.split("/");
    return "/" + parts[1];
  },
  getNotice: function () {},

  // バッジの情報を取得
  getFooterNotification: function () {
    $.ajax({
      url: GIANTS.getPath() + "/api/get_footer_notification",
      cache: false,
      type: "GET",
      success: function (data) {
        if ("count_message" in data && data.count_message > 0) {
          cf_footer_setBadge(function () {}, 0, "N");
        } else {
          cf_footer_setBadge(function () {}, 0, 0); // todo: 0がはいったらバッジが非表示になるか確認する
        }

        if ("count_mission" in data && data.count_mission > 0) {
          cf_footer_setBadge(function () {}, 1, "N");
        } else {
          cf_footer_setBadge(function () {}, 1, 0); // todo: 0がはいったらバッジが非表示になるか確認する
        }

        if ("count_trading" in data && data.count_trading > 0) {
          cf_footer_setBadge(function () {}, 2, "N");
        } else {
          cf_footer_setBadge(function () {}, 2, 0); // todo: 0がはいったらバッジが非表示になるか確認する
        }
      },
    });
  },

  // ユーザーが登録状態になったら、リロード
  afterRegisterdRefresh: function () {
    $.ajax({
      url: GIANTS.getPath() + "/api/is_user_member_registerd",
      cache: false,
      type: "GET",
      success: function (data) {
        if ("is_registerd" in data && data.is_registerd == 1) {
          window.location.reload();
        }
      },
    });
  },

  // ユーザーが登録状態になったら、リロード
  afterMailChangedRefresh: function (token) {
    $.ajax({
      url: GIANTS.getPath() + "/api/is_user_email_changed",
      cache: false,
      type: "GET",
      data: "t=" + token,
      success: function (data) {
        if ("is_complate" in data && data.is_complate == 1) {
          window.location.reload();
        }
      },
    });
  },

  showMessage: function (message, title) {
    var self = this;
    GIANTS.log(self.name + ".showMessage");
    $(document).simpledialog2({
      mode: "button",
      headerText: title,
      headerClose: false,
      buttonPrompt: message,
      buttons: {
        OK: {
          click: function () {
            GIANTS.log("showMessage");
          },
        },
      },
    });
  },
  showError: function (message, title) {
    var self = this;
    GIANTS.log(self.name + ".showLogout");
    var btns = {};
    btns["OK"] = function () {};
    $(document).simpledialog2({
      mode: "button",
      headerText: title,
      headerClose: false,
      buttonPrompt: "画面を再度読み込んでください",
      buttons: btns,
    });
  },
  showConfirm: function (message, title, btnok, btncancel, response) {
    var self = this;
    GIANTS.log(self.name + ".showConfirm");
    var btns = {};
    btns[btnok] = {
      click: function () {
        response(false);
      },
    };
    btns[btncancel] = {
      click: function () {
        response(true);
      },
    };
    $(document).simpledialog2({
      mode: "button",
      headerText: title,
      headerClose: false,
      buttonPrompt: message,
      buttons: btns,
    });
  },
  showLogout: function () {
    var url_top = "../";
    var elm = $("<a>", { href: location.href })[0];
    var ary_tmp = elm.pathname.split("/");
    if (ary_tmp.length > 2) {
      url_top = "/" + ary_tmp[1];
    }

    var self = this;
    GIANTS.log(self.name + ".showLogout");
    var title = "ログアウト";
    var message = "ログアウトしました。<br />自動的にTOPページへ遷移します。";
    var btns = {};
    btns["OK"] = function () {};
    $(document).simpledialog2({
      mode: "button",
      headerText: title,
      headerClose: false,
      buttonPrompt: message,
      buttons: btns,
      callbackOpen: function () {
        setTimeout(function () {
          location.replace(url_top);
        }, 3000);
      },
      callbackClose: function () {
        location.replace(url_top);
      },
    });
  },
  imageView: function (img) {
    var self = this;
    GIANTS.log("GIANTS.imageView");
    var image = $("<img>");
    image.imagesLoaded(function () {
      $.mobile.loading("hide");
      var img = this.images[0].img;
      var width = img.width;
      var height = img.height;
      if (width > 252) {
        height = height * (252 / width);
        img.width = width = 252;
        img.height = height;
      }
      if (height > 300) {
        width = width * (300 / height);
        img.width = width;
        img.height = height = 300;
      }
      img = $(img);
      var top = $("header").height() * 1.2;
      var btns = {};
      btns["閉じる"] = function () {};
      var animate = !GIANTS.isAndroid();
      $(document).simpledialog2({
        mode: "button",
        headerClose: false,
        buttonPrompt: " ",
        buttons: btns,
        top: top,
        animate: animate,
        callbackOpen: function () {
          var title = $(".ui-simpledialog-subtitle");
          title.text("");
          img.appendTo(title);
          var i = self.imgProtect(img);
          i.css("margin", "0 auto 10px auto");
        },
      });
    });
    $.mobile.loading("show");
    var timestamp = new Date().getTime();
    image.attr("src", img.attr("data-path") + "?t=" + timestamp);
  },
  imgProtect: function (img, callback) {
    var src = img.attr("src");
    var width = img.width();
    var height = img.height();
    var i = $(GIANTS.i(src, width + "px", height + "px", "thumb"));
    i.attr("src", src);
    if (callback) i.click(callback);
    img.replaceWith(i);
    return i;
  },
  i: function (src, width, height, cls, id) {
    cls = cls ? " " + cls : "";
    id = id ? 'id="' + id + '" ' : "";
    var i =
      "<i " +
      id +
      'class="img' +
      cls +
      '" style="background-image: url(' +
      "'" +
      src +
      "'" +
      ");background-repeat:no-repeat;background-size:100% 100%;-webkit-background-size:100% 100%;width:" +
      width +
      ";height:" +
      height +
      ';"><img src="/themes/common/img/layout/blank.gif" width="100%" height="100%" /></i>';
    return i;
  },
  textExpand: function (a) {
    var show = $(a).parent();
    var hide = show.parent().find(".text-expand-hide");
    var end = show.parent().find(".text-expand-end");
    end.css("display", "none").remove();
    show.css("display", "none").remove();
    hide.css("position", "relative");
    hide.fadeIn().css("visibility", "visible");
  },
  nl2br: function (s) {
    s = s.replace(/(\r\n){3,}|\r{3,}|\n{3,}/g, "\n\n");
    s = s.replace(/\n/g, "<br>");
    return s;
  },
  openExternal: function (url) {
    // window.open(url, '_blank');
    cf_app_openUrl(function () {}, url, url + "を開けませんでした");
  },
  createLink: function (e) {
    e.contents().each(function () {
      var self = $(this);
      if (self.get(0).nodeName === "A") return;
      if (this.nodeType === 3) {
        var url = GIANTS.getPath() + "/l";
        self.replaceWith(
          self
            .text()
            .replace(
              /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g,
              '<a href="' + url + '?url=$1" target="_blank">$1</a>'
            )
        ); //"
      } else if (self.text().length > 0) {
        if (self.contents().length > 0) {
          GIANTS.createLink(self);
        }
      }
    });
  },
  isAndroid: function () {
    return navigator.userAgent.indexOf("Android") != -1;
  },
  androidVersion: function () {
    var ua = navigator.userAgent;
    var version = "";
    if (ua.indexOf("Android") >= 0) {
      version = parseFloat(ua.slice(ua.indexOf("Android") + 8));
    }
    return version;
  },
  isiOS: function () {
    var ua = navigator.userAgent;
    if (/iPhone/.test(ua)) {
      return true;
    } else if (/iPad/.test(ua)) {
      return true;
    } else if (/iPod/.test(ua)) {
      return true;
    } else {
      return false;
    }
  },
  iosVersion: function () {
    if (!GIANTS.isiOS()) return false;
    var ua = navigator.userAgent;
    if (/iPhone/.test(ua)) {
      ua.match(/iPhone OS (\w+){1,3}/g);
      var version = (RegExp.$1.replace(/_/g, "") + "00").slice(0, 3);
    } else if (/iPad/.test(ua)) {
      ua.match(/CPU OS (\w+){1,3}/g);
      var version = (RegExp.$1.replace(/_/g, "") + "00").slice(0, 3);
    } else {
      var version = false;
    }
    return version;
  },
  loadScript: function (src, callback) {
    if ($('script[src="' + src + '"]').size() != 0) return;
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = src + "?v=" + new Date().getTime();
    if (typeof callback == "function") {
      s.onload = callback;
    }
    document.getElementsByTagName("head")[0].appendChild(s);
  },
  loadStyle: function (href, callback) {
    GIANTS.log(href);
    var l = document.createElement("link");
    l.type = "text/css";
    l.rel = "stylesheet";
    l.href = href + "?v=" + new Date().getTime();
    if (typeof callback == "function") {
      l.onload = callback;
    }
    document.getElementsByTagName("head")[0].appendChild(l);
  },
  stripTags: function (input, allowed) {
    allowed = (
      ((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []
    ).join("");
    var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
      commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
    return input
      .replace(commentsAndPhpTags, "")
      .replace(tags, function ($0, $1) {
        return allowed.indexOf("<" + $1.toLowerCase() + ">") > -1 ? $0 : "";
      });
  },
  trim: function (str) {
    return str.replace(/^\s+|\s+$/g, "");
  },
  ltrim: function (str) {
    return str.replace(/^\s+/, "");
  },
  rtrim: function (str) {
    return str.replace(/\s+$/, "");
  },
  copy: function (text) {
    cf_app_setClipboard(function (success) {
      if (success) {
        alert(text + " をコピーしました");
      } else {
        alert("コピーに失敗しました");
      }
    }, text);
  },
  launch: function (app) {
    return cf_app_launch(function () {}, app);
  },
  outputLogs: function (message) {
    $.ajax({
      url: GIANTS.getPath() + "/api/output_logs",
      type: "POST",
      cache: false,
      data: {
        message: message,
      },
      dataType: "json",
    });
  },
  open_link: function (open_url, tag, is_external) {
    tag = typeof tag !== "undefined" ? tag : "NoTag";
    is_external = typeof is_external !== "undefined" ? is_external : false;
    $.ajax({
      url: GIANTS.getPath() + "/api/touch",
      cache: false,
      data: {
        c: tag,
      },
      type: "POST",
    })
      .done(function () {
        if (is_external) {
          GIANTS.openExternal(open_url);
        } else {
          location.href = open_url;
        }
      })
      .error(function () {
        if (is_external) {
          GIANTS.openExternal(open_url);
        } else {
          location.href = open_url;
        }
      });
  },
};

function _cf_callClientFunction(fncUrl, onReturnCallback) {
  window.webkit.messageHandlers.callbackHandler.postMessage(fncUrl);
}

var cf_app_launch = (onReturnCallback, app) => {
  var fncUrl = "https://client-func/app/launch?app=" + app;
  _cf_callClientFunction(fncUrl, function (success, responseText) {
    onReturnCallback(success);
  });
};

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig();

  return {
    provide: {
      test: test,
      redirect: redirect,
      cf_store_payment: cf_store_payment,
      ImageCacheLoader: ImageCacheLoader(config),
      GIANTS: GIANTS,
    }
  }
})
