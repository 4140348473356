export default defineNuxtRouteMiddleware((to, from) => {
  const path = to.path;
  const hash = to.hash;
  const target_year = to.query.target_year;
  const gacha_case = to.query.case;
  const selected_order_no = to.query.selected_order_no;
  const login = to.query.login;
  const user = to.query.user;
  const trade_id = to.query.id;
  const user_id = to.query.user_id;
  const page = to.query.page;
  const now = to.query.now;
  const uuid = to.query.uuid;
  const mission_id = to.query.mission_id;
  const slot = to.query.slot;
  const section = to.query.section;
  const r = to.query.r;
  const card_id = to.query.card_id;
  const gacha_id = to.query.gacha_id;
  const rare_id = to.query.rare_id;
  const ids = to.query.ids;
  const cnt = to.query.cnt;
  const new_card_ids = to.query.new_card_ids;
  const tab = to.query.tab;
  const effect_skip_flag = to.query.effect_skip_flag;
  const plus = to.query.plus;
  const view = to.query.view;
  if (path.slice(-1) !== "/" && path !== "/404") {
    if (hash) {
      navigateTo(301, path + "/#" + hash);
    } else if (view) {
      navigateTo(301, path + "/?view=" + view);
    } else if (target_year) {
      navigateTo(301, path + "/?target_year=" + target_year);
    } else if (gacha_case) {
      navigateTo(301, path + "/?case=" + gacha_case);
    } else if (selected_order_no) {
      navigateTo(301, path + "/?selected_order_no=" + selected_order_no);
    } else if (login) {
      navigateTo(301, path + "/?login=" + login);
    } else if (user) {
      navigateTo(301, path + "/?user=" + user);
    } else if (trade_id) {
      navigateTo(301, path + "/?trade_id=" + trade_id);
    } else if (user_id, card_id) {
      navigateTo(301, path + "/?user_id=" + user_id + "&card_id=" + card_id);
    } else if (user_id) {
      navigateTo(301, path + "/?user_id=" + user_id);
    } else if (page) {
      navigateTo(301, path + "/?page=" + page);
    } else if (now) {
      navigateTo(301, path + "/?now=" + now);
    } else if (tab, now) {
      navigateTo(301, path + "/?tab=" + tab + "&now=" + now );
    } else if (uuid) {
      navigateTo(301, path + "/?uuid=" + uuid);
    } else if (mission_id) {
      navigateTo(301, path + "/?mission_id=" + mission_id);
    } else if (section) {
      navigateTo(301, path + "/?section=" + section );
    } else if (slot, section) {
      navigateTo(301, path + "/?slot=" + slot + "&section=" + section);
    } else if (slot) {
      navigateTo(301, path + "/?slot=" + slot );
    } else if (r) {
      navigateTo(301, path + "/?r=" + r );
    } else if (rare_id, gacha_id, cnt, effect_skip_flag, plus) {
      navigateTo(301, path + "/?rare_id=" + rare_id + "&gacha_id=" + gacha_id + "&cnt=" + cnt + "&effect_skip_flag=" + effect_skip_flag + "&plus=" + plus);
    } else if (rare_id, ids, cnt, gacha_id, new_card_ids) {
      let paramIds = "";
      let paramCardIds = "";
      ids.forEach((value, index, arr) => {
        paramIds += `&ids[${index}]=${value}`;
      });
      new_card_ids.forEach((value, index, arr) => {
        paramCardIds += `&new_card_ids[${index}]=${value}`;
      });
      navigateTo(301, path + "/?rare_id=" + rare_id + paramIds + "&cnt=" + cnt + "&gacha_id=" + gacha_id + paramCardIds);
    } else {
      navigateTo(301, path + "/");
    }
  }
})
