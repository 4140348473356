export default defineNuxtRouteMiddleware(async (to, from) => {
  // ページ遷移ごとに行いたい処理
  if (to.name) {
    const { data, error } = await useFetch(location.protocol + "//" + location.host + "/version")

    if (error.value) {
      console.error(error.value)
    } else {
      let version = data.value
      if (version instanceof Blob) {
        version = await version.text()
      }
      console.log("version", version);
      if (
        document.cookie
          .split(";")
          .some((item) => item.includes(`version=${version}`))
      ) {
        /*Cookieが一致 何もしない。*/
        //            console.log("version 一致", version);
      } else {
        // version更新時はServiceWorker更新。sw.jsに差分が無ければ更新されない
        const { $pwa } = useNuxtApp();
        await $pwa.updateServiceWorker();

        if (to.path == from.path) {
          // 直リンク、リロードの場合、最新で取得済みのはずなのでリロードなしでクッキー最新化のみで良い
          // ※NuxtLinkによる自己遷移も含まれるが必要性が低いので無いものとして扱う
          document.cookie = `version=${version}`;
        } else {
          // nuxt内で別URLに遷移
          if (
            !document.cookie
                .split(";")
                .some((item) => item.trim().startsWith("version="))
          ) {
            /*Cookie初めて記録*/
            document.cookie = `version=${version}`;
          } else {
            /*Cookieが異なる トップへリロード*/
            document.cookie = `version=${version}`;

            // 末尾スラッシュを補完してから判定
            const pathname = location.pathname.endsWith('/') ? location.pathname : location.pathname + '/';
    
            if (pathname == '/applogin/' ||
              pathname == '/applogin/complete/' ||
              pathname == '/login/' ||
              pathname == '/login/complete/' ||
              pathname == '/tutorial/navi/' ||
              pathname == '/user_policy/'){
            }
            else {
              window.location.href = `/?version=${version}`;
            }
          }
        }
      }
    }
  }
})
