import { default as indexwrVbpHgs9wMeta } from "/var/emtg/card.bleaguepark.jp/pages/allstar_vote/index.vue?macro=true";
import { default as completealwyme56xTMeta } from "/var/emtg/card.bleaguepark.jp/pages/applogin/complete.vue?macro=true";
import { default as failedwcNT92pWtCMeta } from "/var/emtg/card.bleaguepark.jp/pages/applogin/failed.vue?macro=true";
import { default as index2q0ePKOz0iMeta } from "/var/emtg/card.bleaguepark.jp/pages/applogin/index.vue?macro=true";
import { default as _91id_93lvyuCTxJKmMeta } from "/var/emtg/card.bleaguepark.jp/pages/campaign/[id].vue?macro=true";
import { default as _91id_93AAo272y3hYMeta } from "/var/emtg/card.bleaguepark.jp/pages/coupon/[id].vue?macro=true";
import { default as dreamteamuSbPgHeddlMeta } from "/var/emtg/card.bleaguepark.jp/pages/dreamteam.vue?macro=true";
import { default as not_found_userpF4nbDWeTLMeta } from "/var/emtg/card.bleaguepark.jp/pages/error/not_found_user.vue?macro=true";
import { default as errorX1A9NsiV1NMeta } from "/var/emtg/card.bleaguepark.jp/pages/error.vue?macro=true";
import { default as controllerGzjUrJullDMeta } from "/var/emtg/card.bleaguepark.jp/pages/folder/controller.js?macro=true";
import { default as indexU6WHgJXWygMeta } from "/var/emtg/card.bleaguepark.jp/pages/folder/index.vue?macro=true";
import { default as memberbpAfoVdIF2Meta } from "/var/emtg/card.bleaguepark.jp/pages/folder/member.vue?macro=true";
import { default as basicik5IDMtczaMeta } from "/var/emtg/card.bleaguepark.jp/pages/forecast/basic.vue?macro=true";
import { default as detailju4tl1uLydMeta } from "/var/emtg/card.bleaguepark.jp/pages/forecast/bonus/detail.vue?macro=true";
import { default as select_cardPiTzbTmelCMeta } from "/var/emtg/card.bleaguepark.jp/pages/forecast/card/select_card.vue?macro=true";
import { default as effectu4HIgfZckrMeta } from "/var/emtg/card.bleaguepark.jp/pages/forecast/exchange/effect.vue?macro=true";
import { default as detailIVyngu78svMeta } from "/var/emtg/card.bleaguepark.jp/pages/forecast/history/detail.vue?macro=true";
import { default as indexVizaW75cZZMeta } from "/var/emtg/card.bleaguepark.jp/pages/forecast/history/index.vue?macro=true";
import { default as index9q5hbvASWiMeta } from "/var/emtg/card.bleaguepark.jp/pages/forecast/index.vue?macro=true";
import { default as rankingTiho7CsbgaMeta } from "/var/emtg/card.bleaguepark.jp/pages/forecast/ranking.vue?macro=true";
import { default as effectHNKvhl6BfaMeta } from "/var/emtg/card.bleaguepark.jp/pages/forecast/result/effect.vue?macro=true";
import { default as rewardT0EwAe4h1sMeta } from "/var/emtg/card.bleaguepark.jp/pages/forecast/reward.vue?macro=true";
import { default as detailKCUcOiLIX3Meta } from "/var/emtg/card.bleaguepark.jp/pages/forecast/score/detail.vue?macro=true";
import { default as listLrbSeUQP97Meta } from "/var/emtg/card.bleaguepark.jp/pages/forecast/score/list.vue?macro=true";
import { default as ranking6RuEPd4o3DMeta } from "/var/emtg/card.bleaguepark.jp/pages/forecast/season/ranking.vue?macro=true";
import { default as tutorial7NLQpLOlQgMeta } from "/var/emtg/card.bleaguepark.jp/pages/forecast/slide/tutorial.vue?macro=true";
import { default as bonusDsW6YvfEsOMeta } from "/var/emtg/card.bleaguepark.jp/pages/forecast/special/bonus.vue?macro=true";
import { default as unavailabled1YCWFCUhLMeta } from "/var/emtg/card.bleaguepark.jp/pages/forecast/unavailable.vue?macro=true";
import { default as index8dy0rS6v9tMeta } from "/var/emtg/card.bleaguepark.jp/pages/friend/index.vue?macro=true";
import { default as _91id_93LVH1DL8UFRMeta } from "/var/emtg/card.bleaguepark.jp/pages/gacha/contents/[id].vue?macro=true";
import { default as _91id_93VjCgBWMjZlMeta } from "/var/emtg/card.bleaguepark.jp/pages/gacha/detail/[id].vue?macro=true";
import { default as index1KtfUnAiZnMeta } from "/var/emtg/card.bleaguepark.jp/pages/gacha/index.vue?macro=true";
import { default as result_paymentlnHBfl9lpvMeta } from "/var/emtg/card.bleaguepark.jp/pages/gacha/result_payment.vue?macro=true";
import { default as resultMGQoKxsjq4Meta } from "/var/emtg/card.bleaguepark.jp/pages/gacha/result.vue?macro=true";
import { default as indexgFP24RUce0Meta } from "/var/emtg/card.bleaguepark.jp/pages/id_connect/index.vue?macro=true";
import { default as indexLDkhqZdnLIMeta } from "/var/emtg/card.bleaguepark.jp/pages/index.vue?macro=true";
import { default as controller6c35HPSm5uMeta } from "/var/emtg/card.bleaguepark.jp/pages/koukan/controller.js?macro=true";
import { default as index8DEdBQrnwtMeta } from "/var/emtg/card.bleaguepark.jp/pages/koukan/index.vue?macro=true";
import { default as completemBjJRPia67Meta } from "/var/emtg/card.bleaguepark.jp/pages/login/complete.vue?macro=true";
import { default as index89HRunMsCQMeta } from "/var/emtg/card.bleaguepark.jp/pages/login/index.vue?macro=true";
import { default as animationdZ08s9wyISMeta } from "/var/emtg/card.bleaguepark.jp/pages/lottery/[campaign_name]/animation.vue?macro=true";
import { default as indexBEhJFe7kYXMeta } from "/var/emtg/card.bleaguepark.jp/pages/lottery/[campaign_name]/index.vue?macro=true";
import { default as redemptionOAdSVlYMZSMeta } from "/var/emtg/card.bleaguepark.jp/pages/lottery/[campaign_name]/redemption.vue?macro=true";
import { default as resultbbAa9IzPOFMeta } from "/var/emtg/card.bleaguepark.jp/pages/lottery/[campaign_name]/result.vue?macro=true";
import { default as term_of_useFVjOVkrVV7Meta } from "/var/emtg/card.bleaguepark.jp/pages/lottery/[campaign_name]/term_of_use.vue?macro=true";
import { default as detaild3Rnbd4Li1Meta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/bonus/detail.vue?macro=true";
import { default as select_cardOsoMebeYWeMeta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/card/select_card.vue?macro=true";
import { default as deckWpjLtlbTFqMeta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/deck.vue?macro=true";
import { default as gameGpa9yzQIkiMeta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/game/game.vue?macro=true";
import { default as menuTKaHzkrh1JMeta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/game/menu.vue?macro=true";
import { default as resultwCrMfTffPbMeta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/game/result.vue?macro=true";
import { default as indexn9gns0E5m6Meta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/history/index.vue?macro=true";
import { default as indexKBGbdkILsNMeta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/index.vue?macro=true";
import { default as rankingxz6oRuSyGmMeta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/ranking.vue?macro=true";
import { default as resultqrBN1rkqIBMeta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/result.vue?macro=true";
import { default as detailh9D37Bn8N7Meta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/score/detail.vue?macro=true";
import { default as indexBVZbadPxvSMeta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/score/index.vue?macro=true";
import { default as listYZzvOehxSXMeta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/score/list.vue?macro=true";
import { default as season_ranking8fFwGZmCGDMeta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/season_ranking.vue?macro=true";
import { default as select_modeWcWD66kVejMeta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/select_mode.vue?macro=true";
import { default as special_bonusjSn1mkiXCfMeta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/special_bonus.vue?macro=true";
import { default as start_gameNHWVPypD1PMeta } from "/var/emtg/card.bleaguepark.jp/pages/m_arena/start_game.vue?macro=true";
import { default as indexxiGWlcpZSXMeta } from "/var/emtg/card.bleaguepark.jp/pages/match_reward/index.vue?macro=true";
import { default as receive_finish7gEUl6LyfkMeta } from "/var/emtg/card.bleaguepark.jp/pages/match_reward/receive_finish.vue?macro=true";
import { default as howtoZ1vz3mC61yMeta } from "/var/emtg/card.bleaguepark.jp/pages/menu/howto.vue?macro=true";
import { default as indexdIYskdZNlYMeta } from "/var/emtg/card.bleaguepark.jp/pages/menu/index.vue?macro=true";
import { default as indexhrMxG4VGG9Meta } from "/var/emtg/card.bleaguepark.jp/pages/minimail/index.vue?macro=true";
import { default as classa5nDilMd11Meta } from "/var/emtg/card.bleaguepark.jp/pages/mission/class.vue?macro=true";
import { default as indexQmVFm4ZxVbMeta } from "/var/emtg/card.bleaguepark.jp/pages/mission/index.vue?macro=true";
import { default as normalEd3uVyKUiJMeta } from "/var/emtg/card.bleaguepark.jp/pages/mission/normal.vue?macro=true";
import { default as t_indexE1HLpwdoZ4Meta } from "/var/emtg/card.bleaguepark.jp/pages/mission/t_index.vue?macro=true";
import { default as _91page_name_93USuT48QWGkMeta } from "/var/emtg/card.bleaguepark.jp/pages/news/[page_name].vue?macro=true";
import { default as kuji202306cHRRyUHw0SMeta } from "/var/emtg/card.bleaguepark.jp/pages/news/kuji202306.vue?macro=true";
import { default as kuji202310sZ5nC1hQrlMeta } from "/var/emtg/card.bleaguepark.jp/pages/news/kuji202310.vue?macro=true";
import { default as indexS08n5kgKeIMeta } from "/var/emtg/card.bleaguepark.jp/pages/nft_connect/index.vue?macro=true";
import { default as _91page_name_93O1Oc7wqTzAMeta } from "/var/emtg/card.bleaguepark.jp/pages/page/[page_name].vue?macro=true";
import { default as _91id_93cJP3jgfkwEMeta } from "/var/emtg/card.bleaguepark.jp/pages/panel_mission/[id].vue?macro=true";
import { default as _91_91page_93_93ZiDe2j6BiuMeta } from "/var/emtg/card.bleaguepark.jp/pages/present/[[page]].vue?macro=true";
import { default as index8vtKLiLD74Meta } from "/var/emtg/card.bleaguepark.jp/pages/privacy/index.vue?macro=true";
import { default as backkTrWLmfO3WMeta } from "/var/emtg/card.bleaguepark.jp/pages/profile/back.vue?macro=true";
import { default as backgroundSSmzGfJJRUMeta } from "/var/emtg/card.bleaguepark.jp/pages/profile/background.vue?macro=true";
import { default as editp2BDw3yOloMeta } from "/var/emtg/card.bleaguepark.jp/pages/profile/edit.vue?macro=true";
import { default as frontR7b2UdW4fLMeta } from "/var/emtg/card.bleaguepark.jp/pages/profile/front.vue?macro=true";
import { default as image_upladerY7Z7ds0uplMeta } from "/var/emtg/card.bleaguepark.jp/pages/profile/image_uplader.vue?macro=true";
import { default as indexCeOoCbOWxJMeta } from "/var/emtg/card.bleaguepark.jp/pages/profile/index.vue?macro=true";
import { default as titleUUtvEWaJVQMeta } from "/var/emtg/card.bleaguepark.jp/pages/profile/title.vue?macro=true";
import { default as indexMWxQTim6TBMeta } from "/var/emtg/card.bleaguepark.jp/pages/questionnaire/index.vue?macro=true";
import { default as indexufvoXuLcAHMeta } from "/var/emtg/card.bleaguepark.jp/pages/rarerank/index.vue?macro=true";
import { default as indexsmQ6k1UA8hMeta } from "/var/emtg/card.bleaguepark.jp/pages/rarerank/summary/index.vue?macro=true";
import { default as summaryWkDB3kVgPfMeta } from "/var/emtg/card.bleaguepark.jp/pages/rarerank/summary.vue?macro=true";
import { default as detail5dLbjFWGKTMeta } from "/var/emtg/card.bleaguepark.jp/pages/sales/detail.vue?macro=true";
import { default as listcmtQxV2UqvMeta } from "/var/emtg/card.bleaguepark.jp/pages/sales/list.vue?macro=true";
import { default as resultD56xVG4AJ0Meta } from "/var/emtg/card.bleaguepark.jp/pages/sales/result.vue?macro=true";
import { default as indexOqToVYMftaMeta } from "/var/emtg/card.bleaguepark.jp/pages/select_club/index.vue?macro=true";
import { default as indexXcL7W9VlWdMeta } from "/var/emtg/card.bleaguepark.jp/pages/serial/index.vue?macro=true";
import { default as indexbq38T9k0IXMeta } from "/var/emtg/card.bleaguepark.jp/pages/setting/index.vue?macro=true";
import { default as indexNO517MK1R4Meta } from "/var/emtg/card.bleaguepark.jp/pages/title/index.vue?macro=true";
import { default as indexpa8b3oOsBjMeta } from "/var/emtg/card.bleaguepark.jp/pages/trade/index.vue?macro=true";
import { default as indextLJrUsXvkGMeta } from "/var/emtg/card.bleaguepark.jp/pages/trophy/index.vue?macro=true";
import { default as memberXRxRgjw1a2Meta } from "/var/emtg/card.bleaguepark.jp/pages/trophy/member.vue?macro=true";
import { default as navi4tdoZ03RrVMeta } from "/var/emtg/card.bleaguepark.jp/pages/tutorial/navi.vue?macro=true";
import { default as act_on_settlement2KBGZrGmqIMeta } from "/var/emtg/card.bleaguepark.jp/pages/user_policy/act_on_settlement.vue?macro=true";
import { default as indexhQS6wXk0hKMeta } from "/var/emtg/card.bleaguepark.jp/pages/user_policy/index.vue?macro=true";
import { default as tokushoXiGuNb5jkzMeta } from "/var/emtg/card.bleaguepark.jp/pages/user_policy/tokusho.vue?macro=true";
import { default as transfer_confirm1WLOld6YQ1Meta } from "/var/emtg/card.bleaguepark.jp/pages/user/account/transfer_confirm.vue?macro=true";
import { default as transferLzwxcSKyx9Meta } from "/var/emtg/card.bleaguepark.jp/pages/user/account/transfer.vue?macro=true";
import { default as crystal_purchasefYY1tj9l9ZMeta } from "/var/emtg/card.bleaguepark.jp/pages/user/crystal_purchase.vue?macro=true";
import { default as serialtKcj4nrB6sMeta } from "/var/emtg/card.bleaguepark.jp/pages/user/input/serial.vue?macro=true";
import { default as indexFfpTCqoZycMeta } from "/var/emtg/card.bleaguepark.jp/pages/user/login/index.vue?macro=true";
import { default as loginbytidKq8n7ICGJgMeta } from "/var/emtg/card.bleaguepark.jp/pages/user/login/loginbytid.vue?macro=true";
import { default as _91uid_93niCxswdaELMeta } from "/var/emtg/card.bleaguepark.jp/pages/user/mypage/[uid].vue?macro=true";
import { default as controllerZiuoGjhPoCMeta } from "/var/emtg/card.bleaguepark.jp/pages/user/mypage/controller.js?macro=true";
import { default as pointhistory4RUyZhJfNRMeta } from "/var/emtg/card.bleaguepark.jp/pages/user/pointhistory.vue?macro=true";
import { default as preview_cards_tmpMhymxY5kfbMeta } from "/var/emtg/card.bleaguepark.jp/pages/user/preview_cards_tmp.vue?macro=true";
import { default as search_uid0iIZZTK1I8Meta } from "/var/emtg/card.bleaguepark.jp/pages/user/search_uid.vue?macro=true";
import { default as allstarhw1qEyatByMeta } from "/var/emtg/card.bleaguepark.jp/pages/vote/allstar.vue?macro=true";
import { default as allstar20215jgQjyoh32Meta } from "/var/emtg/card.bleaguepark.jp/pages/vote/allstar2021.vue?macro=true";
import { default as altairyUF53J00fhMeta } from "/var/emtg/card.bleaguepark.jp/pages/vote/altair.vue?macro=true";
import { default as card_serieszsi7on7YXMMeta } from "/var/emtg/card.bleaguepark.jp/pages/vote/card_series.vue?macro=true";
import { default as _91deckId_93Dm4nWGcXf4Meta } from "/var/emtg/card.bleaguepark.jp/pages/vote/dreamteam/[deckId].vue?macro=true";
import { default as indexnJMCJgHp1SMeta } from "/var/emtg/card.bleaguepark.jp/pages/vote/index.vue?macro=true";
import { default as mascoto9Wuo1OEqBMeta } from "/var/emtg/card.bleaguepark.jp/pages/vote/mascot.vue?macro=true";
import { default as valentineEVIX7OKVGKMeta } from "/var/emtg/card.bleaguepark.jp/pages/vote/valentine.vue?macro=true";
export default [
  {
    name: indexwrVbpHgs9wMeta?.name ?? "allstar_vote",
    path: indexwrVbpHgs9wMeta?.path ?? "/allstar_vote",
    meta: indexwrVbpHgs9wMeta || {},
    alias: indexwrVbpHgs9wMeta?.alias || [],
    redirect: indexwrVbpHgs9wMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/allstar_vote/index.vue").then(m => m.default || m)
  },
  {
    name: completealwyme56xTMeta?.name ?? "applogin-complete",
    path: completealwyme56xTMeta?.path ?? "/applogin/complete",
    meta: completealwyme56xTMeta || {},
    alias: completealwyme56xTMeta?.alias || [],
    redirect: completealwyme56xTMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/applogin/complete.vue").then(m => m.default || m)
  },
  {
    name: failedwcNT92pWtCMeta?.name ?? "applogin-failed",
    path: failedwcNT92pWtCMeta?.path ?? "/applogin/failed",
    meta: failedwcNT92pWtCMeta || {},
    alias: failedwcNT92pWtCMeta?.alias || [],
    redirect: failedwcNT92pWtCMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/applogin/failed.vue").then(m => m.default || m)
  },
  {
    name: index2q0ePKOz0iMeta?.name ?? "applogin",
    path: index2q0ePKOz0iMeta?.path ?? "/applogin",
    meta: index2q0ePKOz0iMeta || {},
    alias: index2q0ePKOz0iMeta?.alias || [],
    redirect: index2q0ePKOz0iMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/applogin/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93lvyuCTxJKmMeta?.name ?? "campaign-id",
    path: _91id_93lvyuCTxJKmMeta?.path ?? "/campaign/:id()",
    meta: _91id_93lvyuCTxJKmMeta || {},
    alias: _91id_93lvyuCTxJKmMeta?.alias || [],
    redirect: _91id_93lvyuCTxJKmMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/campaign/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93AAo272y3hYMeta?.name ?? "coupon-id",
    path: _91id_93AAo272y3hYMeta?.path ?? "/coupon/:id()",
    meta: _91id_93AAo272y3hYMeta || {},
    alias: _91id_93AAo272y3hYMeta?.alias || [],
    redirect: _91id_93AAo272y3hYMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/coupon/[id].vue").then(m => m.default || m)
  },
  {
    name: dreamteamuSbPgHeddlMeta?.name ?? "dreamteam",
    path: dreamteamuSbPgHeddlMeta?.path ?? "/dreamteam",
    meta: dreamteamuSbPgHeddlMeta || {},
    alias: dreamteamuSbPgHeddlMeta?.alias || [],
    redirect: dreamteamuSbPgHeddlMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/dreamteam.vue").then(m => m.default || m)
  },
  {
    name: errorX1A9NsiV1NMeta?.name ?? "error",
    path: errorX1A9NsiV1NMeta?.path ?? "/error",
    children: [
  {
    name: not_found_userpF4nbDWeTLMeta?.name ?? "error-not_found_user",
    path: not_found_userpF4nbDWeTLMeta?.path ?? "not_found_user",
    meta: not_found_userpF4nbDWeTLMeta || {},
    alias: not_found_userpF4nbDWeTLMeta?.alias || [],
    redirect: not_found_userpF4nbDWeTLMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/error/not_found_user.vue").then(m => m.default || m)
  }
],
    meta: errorX1A9NsiV1NMeta || {},
    alias: errorX1A9NsiV1NMeta?.alias || [],
    redirect: errorX1A9NsiV1NMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/error.vue").then(m => m.default || m)
  },
  {
    name: controllerGzjUrJullDMeta?.name ?? "folder-controller",
    path: controllerGzjUrJullDMeta?.path ?? "/folder/controller",
    meta: controllerGzjUrJullDMeta || {},
    alias: controllerGzjUrJullDMeta?.alias || [],
    redirect: controllerGzjUrJullDMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/folder/controller.js").then(m => m.default || m)
  },
  {
    name: indexU6WHgJXWygMeta?.name ?? "folder",
    path: indexU6WHgJXWygMeta?.path ?? "/folder",
    meta: indexU6WHgJXWygMeta || {},
    alias: indexU6WHgJXWygMeta?.alias || [],
    redirect: indexU6WHgJXWygMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/folder/index.vue").then(m => m.default || m)
  },
  {
    name: memberbpAfoVdIF2Meta?.name ?? "folder-member",
    path: memberbpAfoVdIF2Meta?.path ?? "/folder/member",
    meta: memberbpAfoVdIF2Meta || {},
    alias: memberbpAfoVdIF2Meta?.alias || [],
    redirect: memberbpAfoVdIF2Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/folder/member.vue").then(m => m.default || m)
  },
  {
    name: basicik5IDMtczaMeta?.name ?? "forecast-basic",
    path: basicik5IDMtczaMeta?.path ?? "/forecast/basic",
    meta: basicik5IDMtczaMeta || {},
    alias: basicik5IDMtczaMeta?.alias || [],
    redirect: basicik5IDMtczaMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/forecast/basic.vue").then(m => m.default || m)
  },
  {
    name: detailju4tl1uLydMeta?.name ?? "forecast-bonus-detail",
    path: detailju4tl1uLydMeta?.path ?? "/forecast/bonus/detail",
    meta: detailju4tl1uLydMeta || {},
    alias: detailju4tl1uLydMeta?.alias || [],
    redirect: detailju4tl1uLydMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/forecast/bonus/detail.vue").then(m => m.default || m)
  },
  {
    name: select_cardPiTzbTmelCMeta?.name ?? "forecast-card-select_card",
    path: select_cardPiTzbTmelCMeta?.path ?? "/forecast/card/select_card",
    meta: select_cardPiTzbTmelCMeta || {},
    alias: select_cardPiTzbTmelCMeta?.alias || [],
    redirect: select_cardPiTzbTmelCMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/forecast/card/select_card.vue").then(m => m.default || m)
  },
  {
    name: effectu4HIgfZckrMeta?.name ?? "forecast-exchange-effect",
    path: effectu4HIgfZckrMeta?.path ?? "/forecast/exchange/effect",
    meta: effectu4HIgfZckrMeta || {},
    alias: effectu4HIgfZckrMeta?.alias || [],
    redirect: effectu4HIgfZckrMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/forecast/exchange/effect.vue").then(m => m.default || m)
  },
  {
    name: detailIVyngu78svMeta?.name ?? "forecast-history-detail",
    path: detailIVyngu78svMeta?.path ?? "/forecast/history/detail",
    meta: detailIVyngu78svMeta || {},
    alias: detailIVyngu78svMeta?.alias || [],
    redirect: detailIVyngu78svMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/forecast/history/detail.vue").then(m => m.default || m)
  },
  {
    name: indexVizaW75cZZMeta?.name ?? "forecast-history",
    path: indexVizaW75cZZMeta?.path ?? "/forecast/history",
    meta: indexVizaW75cZZMeta || {},
    alias: indexVizaW75cZZMeta?.alias || [],
    redirect: indexVizaW75cZZMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/forecast/history/index.vue").then(m => m.default || m)
  },
  {
    name: index9q5hbvASWiMeta?.name ?? "forecast",
    path: index9q5hbvASWiMeta?.path ?? "/forecast",
    meta: index9q5hbvASWiMeta || {},
    alias: index9q5hbvASWiMeta?.alias || [],
    redirect: index9q5hbvASWiMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/forecast/index.vue").then(m => m.default || m)
  },
  {
    name: rankingTiho7CsbgaMeta?.name ?? "forecast-ranking",
    path: rankingTiho7CsbgaMeta?.path ?? "/forecast/ranking",
    meta: rankingTiho7CsbgaMeta || {},
    alias: rankingTiho7CsbgaMeta?.alias || [],
    redirect: rankingTiho7CsbgaMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/forecast/ranking.vue").then(m => m.default || m)
  },
  {
    name: effectHNKvhl6BfaMeta?.name ?? "forecast-result-effect",
    path: effectHNKvhl6BfaMeta?.path ?? "/forecast/result/effect",
    meta: effectHNKvhl6BfaMeta || {},
    alias: effectHNKvhl6BfaMeta?.alias || [],
    redirect: effectHNKvhl6BfaMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/forecast/result/effect.vue").then(m => m.default || m)
  },
  {
    name: rewardT0EwAe4h1sMeta?.name ?? "forecast-reward",
    path: rewardT0EwAe4h1sMeta?.path ?? "/forecast/reward",
    meta: rewardT0EwAe4h1sMeta || {},
    alias: rewardT0EwAe4h1sMeta?.alias || [],
    redirect: rewardT0EwAe4h1sMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/forecast/reward.vue").then(m => m.default || m)
  },
  {
    name: detailKCUcOiLIX3Meta?.name ?? "forecast-score-detail",
    path: detailKCUcOiLIX3Meta?.path ?? "/forecast/score/detail",
    meta: detailKCUcOiLIX3Meta || {},
    alias: detailKCUcOiLIX3Meta?.alias || [],
    redirect: detailKCUcOiLIX3Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/forecast/score/detail.vue").then(m => m.default || m)
  },
  {
    name: listLrbSeUQP97Meta?.name ?? "forecast-score-list",
    path: listLrbSeUQP97Meta?.path ?? "/forecast/score/list",
    meta: listLrbSeUQP97Meta || {},
    alias: listLrbSeUQP97Meta?.alias || [],
    redirect: listLrbSeUQP97Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/forecast/score/list.vue").then(m => m.default || m)
  },
  {
    name: ranking6RuEPd4o3DMeta?.name ?? "forecast-season-ranking",
    path: ranking6RuEPd4o3DMeta?.path ?? "/forecast/season/ranking",
    meta: ranking6RuEPd4o3DMeta || {},
    alias: ranking6RuEPd4o3DMeta?.alias || [],
    redirect: ranking6RuEPd4o3DMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/forecast/season/ranking.vue").then(m => m.default || m)
  },
  {
    name: tutorial7NLQpLOlQgMeta?.name ?? "forecast-slide-tutorial",
    path: tutorial7NLQpLOlQgMeta?.path ?? "/forecast/slide/tutorial",
    meta: tutorial7NLQpLOlQgMeta || {},
    alias: tutorial7NLQpLOlQgMeta?.alias || [],
    redirect: tutorial7NLQpLOlQgMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/forecast/slide/tutorial.vue").then(m => m.default || m)
  },
  {
    name: bonusDsW6YvfEsOMeta?.name ?? "forecast-special-bonus",
    path: bonusDsW6YvfEsOMeta?.path ?? "/forecast/special/bonus",
    meta: bonusDsW6YvfEsOMeta || {},
    alias: bonusDsW6YvfEsOMeta?.alias || [],
    redirect: bonusDsW6YvfEsOMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/forecast/special/bonus.vue").then(m => m.default || m)
  },
  {
    name: unavailabled1YCWFCUhLMeta?.name ?? "forecast-unavailable",
    path: unavailabled1YCWFCUhLMeta?.path ?? "/forecast/unavailable",
    meta: unavailabled1YCWFCUhLMeta || {},
    alias: unavailabled1YCWFCUhLMeta?.alias || [],
    redirect: unavailabled1YCWFCUhLMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/forecast/unavailable.vue").then(m => m.default || m)
  },
  {
    name: index8dy0rS6v9tMeta?.name ?? "friend",
    path: index8dy0rS6v9tMeta?.path ?? "/friend",
    meta: index8dy0rS6v9tMeta || {},
    alias: index8dy0rS6v9tMeta?.alias || [],
    redirect: index8dy0rS6v9tMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/friend/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LVH1DL8UFRMeta?.name ?? "gacha-contents-id",
    path: _91id_93LVH1DL8UFRMeta?.path ?? "/gacha/contents/:id()",
    meta: _91id_93LVH1DL8UFRMeta || {},
    alias: _91id_93LVH1DL8UFRMeta?.alias || [],
    redirect: _91id_93LVH1DL8UFRMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/gacha/contents/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93VjCgBWMjZlMeta?.name ?? "gacha-detail-id",
    path: _91id_93VjCgBWMjZlMeta?.path ?? "/gacha/detail/:id()",
    meta: _91id_93VjCgBWMjZlMeta || {},
    alias: _91id_93VjCgBWMjZlMeta?.alias || [],
    redirect: _91id_93VjCgBWMjZlMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/gacha/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: index1KtfUnAiZnMeta?.name ?? "gacha",
    path: index1KtfUnAiZnMeta?.path ?? "/gacha",
    meta: index1KtfUnAiZnMeta || {},
    alias: index1KtfUnAiZnMeta?.alias || [],
    redirect: index1KtfUnAiZnMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/gacha/index.vue").then(m => m.default || m)
  },
  {
    name: result_paymentlnHBfl9lpvMeta?.name ?? "gacha-result_payment",
    path: result_paymentlnHBfl9lpvMeta?.path ?? "/gacha/result_payment",
    meta: result_paymentlnHBfl9lpvMeta || {},
    alias: result_paymentlnHBfl9lpvMeta?.alias || [],
    redirect: result_paymentlnHBfl9lpvMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/gacha/result_payment.vue").then(m => m.default || m)
  },
  {
    name: resultMGQoKxsjq4Meta?.name ?? "gacha-result",
    path: resultMGQoKxsjq4Meta?.path ?? "/gacha/result",
    meta: resultMGQoKxsjq4Meta || {},
    alias: resultMGQoKxsjq4Meta?.alias || [],
    redirect: resultMGQoKxsjq4Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/gacha/result.vue").then(m => m.default || m)
  },
  {
    name: indexgFP24RUce0Meta?.name ?? "id_connect",
    path: indexgFP24RUce0Meta?.path ?? "/id_connect",
    meta: indexgFP24RUce0Meta || {},
    alias: indexgFP24RUce0Meta?.alias || [],
    redirect: indexgFP24RUce0Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/id_connect/index.vue").then(m => m.default || m)
  },
  {
    name: indexLDkhqZdnLIMeta?.name ?? "index",
    path: indexLDkhqZdnLIMeta?.path ?? "/",
    meta: indexLDkhqZdnLIMeta || {},
    alias: indexLDkhqZdnLIMeta?.alias || [],
    redirect: indexLDkhqZdnLIMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: controller6c35HPSm5uMeta?.name ?? "koukan-controller",
    path: controller6c35HPSm5uMeta?.path ?? "/koukan/controller",
    meta: controller6c35HPSm5uMeta || {},
    alias: controller6c35HPSm5uMeta?.alias || [],
    redirect: controller6c35HPSm5uMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/koukan/controller.js").then(m => m.default || m)
  },
  {
    name: index8DEdBQrnwtMeta?.name ?? "koukan",
    path: index8DEdBQrnwtMeta?.path ?? "/koukan",
    meta: index8DEdBQrnwtMeta || {},
    alias: index8DEdBQrnwtMeta?.alias || [],
    redirect: index8DEdBQrnwtMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/koukan/index.vue").then(m => m.default || m)
  },
  {
    name: completemBjJRPia67Meta?.name ?? "login-complete",
    path: completemBjJRPia67Meta?.path ?? "/login/complete",
    meta: completemBjJRPia67Meta || {},
    alias: completemBjJRPia67Meta?.alias || [],
    redirect: completemBjJRPia67Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/login/complete.vue").then(m => m.default || m)
  },
  {
    name: index89HRunMsCQMeta?.name ?? "login",
    path: index89HRunMsCQMeta?.path ?? "/login",
    meta: index89HRunMsCQMeta || {},
    alias: index89HRunMsCQMeta?.alias || [],
    redirect: index89HRunMsCQMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: animationdZ08s9wyISMeta?.name ?? "lottery-campaign_name-animation",
    path: animationdZ08s9wyISMeta?.path ?? "/lottery/:campaign_name()/animation",
    meta: animationdZ08s9wyISMeta || {},
    alias: animationdZ08s9wyISMeta?.alias || [],
    redirect: animationdZ08s9wyISMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/lottery/[campaign_name]/animation.vue").then(m => m.default || m)
  },
  {
    name: indexBEhJFe7kYXMeta?.name ?? "lottery-campaign_name",
    path: indexBEhJFe7kYXMeta?.path ?? "/lottery/:campaign_name()",
    meta: indexBEhJFe7kYXMeta || {},
    alias: indexBEhJFe7kYXMeta?.alias || [],
    redirect: indexBEhJFe7kYXMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/lottery/[campaign_name]/index.vue").then(m => m.default || m)
  },
  {
    name: redemptionOAdSVlYMZSMeta?.name ?? "lottery-campaign_name-redemption",
    path: redemptionOAdSVlYMZSMeta?.path ?? "/lottery/:campaign_name()/redemption",
    meta: redemptionOAdSVlYMZSMeta || {},
    alias: redemptionOAdSVlYMZSMeta?.alias || [],
    redirect: redemptionOAdSVlYMZSMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/lottery/[campaign_name]/redemption.vue").then(m => m.default || m)
  },
  {
    name: resultbbAa9IzPOFMeta?.name ?? "lottery-campaign_name-result",
    path: resultbbAa9IzPOFMeta?.path ?? "/lottery/:campaign_name()/result",
    meta: resultbbAa9IzPOFMeta || {},
    alias: resultbbAa9IzPOFMeta?.alias || [],
    redirect: resultbbAa9IzPOFMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/lottery/[campaign_name]/result.vue").then(m => m.default || m)
  },
  {
    name: term_of_useFVjOVkrVV7Meta?.name ?? "lottery-campaign_name-term_of_use",
    path: term_of_useFVjOVkrVV7Meta?.path ?? "/lottery/:campaign_name()/term_of_use",
    meta: term_of_useFVjOVkrVV7Meta || {},
    alias: term_of_useFVjOVkrVV7Meta?.alias || [],
    redirect: term_of_useFVjOVkrVV7Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/lottery/[campaign_name]/term_of_use.vue").then(m => m.default || m)
  },
  {
    name: detaild3Rnbd4Li1Meta?.name ?? "m_arena-bonus-detail",
    path: detaild3Rnbd4Li1Meta?.path ?? "/m_arena/bonus/detail",
    meta: detaild3Rnbd4Li1Meta || {},
    alias: detaild3Rnbd4Li1Meta?.alias || [],
    redirect: detaild3Rnbd4Li1Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/bonus/detail.vue").then(m => m.default || m)
  },
  {
    name: select_cardOsoMebeYWeMeta?.name ?? "m_arena-card-select_card",
    path: select_cardOsoMebeYWeMeta?.path ?? "/m_arena/card/select_card",
    meta: select_cardOsoMebeYWeMeta || {},
    alias: select_cardOsoMebeYWeMeta?.alias || [],
    redirect: select_cardOsoMebeYWeMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/card/select_card.vue").then(m => m.default || m)
  },
  {
    name: deckWpjLtlbTFqMeta?.name ?? "m_arena-deck",
    path: deckWpjLtlbTFqMeta?.path ?? "/m_arena/deck",
    meta: deckWpjLtlbTFqMeta || {},
    alias: deckWpjLtlbTFqMeta?.alias || [],
    redirect: deckWpjLtlbTFqMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/deck.vue").then(m => m.default || m)
  },
  {
    name: gameGpa9yzQIkiMeta?.name ?? "m_arena-game-game",
    path: gameGpa9yzQIkiMeta?.path ?? "/m_arena/game/game",
    meta: gameGpa9yzQIkiMeta || {},
    alias: gameGpa9yzQIkiMeta?.alias || [],
    redirect: gameGpa9yzQIkiMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/game/game.vue").then(m => m.default || m)
  },
  {
    name: menuTKaHzkrh1JMeta?.name ?? "m_arena-game-menu",
    path: menuTKaHzkrh1JMeta?.path ?? "/m_arena/game/menu",
    meta: menuTKaHzkrh1JMeta || {},
    alias: menuTKaHzkrh1JMeta?.alias || [],
    redirect: menuTKaHzkrh1JMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/game/menu.vue").then(m => m.default || m)
  },
  {
    name: resultwCrMfTffPbMeta?.name ?? "m_arena-game-result",
    path: resultwCrMfTffPbMeta?.path ?? "/m_arena/game/result",
    meta: resultwCrMfTffPbMeta || {},
    alias: resultwCrMfTffPbMeta?.alias || [],
    redirect: resultwCrMfTffPbMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/game/result.vue").then(m => m.default || m)
  },
  {
    name: indexn9gns0E5m6Meta?.name ?? "m_arena-history",
    path: indexn9gns0E5m6Meta?.path ?? "/m_arena/history",
    meta: indexn9gns0E5m6Meta || {},
    alias: indexn9gns0E5m6Meta?.alias || [],
    redirect: indexn9gns0E5m6Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/history/index.vue").then(m => m.default || m)
  },
  {
    name: indexKBGbdkILsNMeta?.name ?? "m_arena",
    path: indexKBGbdkILsNMeta?.path ?? "/m_arena",
    meta: indexKBGbdkILsNMeta || {},
    alias: indexKBGbdkILsNMeta?.alias || [],
    redirect: indexKBGbdkILsNMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/index.vue").then(m => m.default || m)
  },
  {
    name: rankingxz6oRuSyGmMeta?.name ?? "m_arena-ranking",
    path: rankingxz6oRuSyGmMeta?.path ?? "/m_arena/ranking",
    meta: rankingxz6oRuSyGmMeta || {},
    alias: rankingxz6oRuSyGmMeta?.alias || [],
    redirect: rankingxz6oRuSyGmMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/ranking.vue").then(m => m.default || m)
  },
  {
    name: resultqrBN1rkqIBMeta?.name ?? "m_arena-result",
    path: resultqrBN1rkqIBMeta?.path ?? "/m_arena/result",
    meta: resultqrBN1rkqIBMeta || {},
    alias: resultqrBN1rkqIBMeta?.alias || [],
    redirect: resultqrBN1rkqIBMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/result.vue").then(m => m.default || m)
  },
  {
    name: detailh9D37Bn8N7Meta?.name ?? "m_arena-score-detail",
    path: detailh9D37Bn8N7Meta?.path ?? "/m_arena/score/detail",
    meta: detailh9D37Bn8N7Meta || {},
    alias: detailh9D37Bn8N7Meta?.alias || [],
    redirect: detailh9D37Bn8N7Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/score/detail.vue").then(m => m.default || m)
  },
  {
    name: indexBVZbadPxvSMeta?.name ?? "m_arena-score",
    path: indexBVZbadPxvSMeta?.path ?? "/m_arena/score",
    meta: indexBVZbadPxvSMeta || {},
    alias: indexBVZbadPxvSMeta?.alias || [],
    redirect: indexBVZbadPxvSMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/score/index.vue").then(m => m.default || m)
  },
  {
    name: listYZzvOehxSXMeta?.name ?? "m_arena-score-list",
    path: listYZzvOehxSXMeta?.path ?? "/m_arena/score/list",
    meta: listYZzvOehxSXMeta || {},
    alias: listYZzvOehxSXMeta?.alias || [],
    redirect: listYZzvOehxSXMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/score/list.vue").then(m => m.default || m)
  },
  {
    name: season_ranking8fFwGZmCGDMeta?.name ?? "m_arena-season_ranking",
    path: season_ranking8fFwGZmCGDMeta?.path ?? "/m_arena/season_ranking",
    meta: season_ranking8fFwGZmCGDMeta || {},
    alias: season_ranking8fFwGZmCGDMeta?.alias || [],
    redirect: season_ranking8fFwGZmCGDMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/season_ranking.vue").then(m => m.default || m)
  },
  {
    name: select_modeWcWD66kVejMeta?.name ?? "m_arena-select_mode",
    path: select_modeWcWD66kVejMeta?.path ?? "/m_arena/select_mode",
    meta: select_modeWcWD66kVejMeta || {},
    alias: select_modeWcWD66kVejMeta?.alias || [],
    redirect: select_modeWcWD66kVejMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/select_mode.vue").then(m => m.default || m)
  },
  {
    name: special_bonusjSn1mkiXCfMeta?.name ?? "m_arena-special_bonus",
    path: special_bonusjSn1mkiXCfMeta?.path ?? "/m_arena/special_bonus",
    meta: special_bonusjSn1mkiXCfMeta || {},
    alias: special_bonusjSn1mkiXCfMeta?.alias || [],
    redirect: special_bonusjSn1mkiXCfMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/special_bonus.vue").then(m => m.default || m)
  },
  {
    name: start_gameNHWVPypD1PMeta?.name ?? "m_arena-start_game",
    path: start_gameNHWVPypD1PMeta?.path ?? "/m_arena/start_game",
    meta: start_gameNHWVPypD1PMeta || {},
    alias: start_gameNHWVPypD1PMeta?.alias || [],
    redirect: start_gameNHWVPypD1PMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/m_arena/start_game.vue").then(m => m.default || m)
  },
  {
    name: indexxiGWlcpZSXMeta?.name ?? "match_reward",
    path: indexxiGWlcpZSXMeta?.path ?? "/match_reward",
    meta: indexxiGWlcpZSXMeta || {},
    alias: indexxiGWlcpZSXMeta?.alias || [],
    redirect: indexxiGWlcpZSXMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/match_reward/index.vue").then(m => m.default || m)
  },
  {
    name: receive_finish7gEUl6LyfkMeta?.name ?? "match_reward-receive_finish",
    path: receive_finish7gEUl6LyfkMeta?.path ?? "/match_reward/receive_finish",
    meta: receive_finish7gEUl6LyfkMeta || {},
    alias: receive_finish7gEUl6LyfkMeta?.alias || [],
    redirect: receive_finish7gEUl6LyfkMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/match_reward/receive_finish.vue").then(m => m.default || m)
  },
  {
    name: howtoZ1vz3mC61yMeta?.name ?? "menu-howto",
    path: howtoZ1vz3mC61yMeta?.path ?? "/menu/howto",
    meta: howtoZ1vz3mC61yMeta || {},
    alias: howtoZ1vz3mC61yMeta?.alias || [],
    redirect: howtoZ1vz3mC61yMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/menu/howto.vue").then(m => m.default || m)
  },
  {
    name: indexdIYskdZNlYMeta?.name ?? "menu",
    path: indexdIYskdZNlYMeta?.path ?? "/menu",
    meta: indexdIYskdZNlYMeta || {},
    alias: indexdIYskdZNlYMeta?.alias || [],
    redirect: indexdIYskdZNlYMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/menu/index.vue").then(m => m.default || m)
  },
  {
    name: indexhrMxG4VGG9Meta?.name ?? "minimail",
    path: indexhrMxG4VGG9Meta?.path ?? "/minimail",
    meta: indexhrMxG4VGG9Meta || {},
    alias: indexhrMxG4VGG9Meta?.alias || [],
    redirect: indexhrMxG4VGG9Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/minimail/index.vue").then(m => m.default || m)
  },
  {
    name: classa5nDilMd11Meta?.name ?? "mission-class",
    path: classa5nDilMd11Meta?.path ?? "/mission/class",
    meta: classa5nDilMd11Meta || {},
    alias: classa5nDilMd11Meta?.alias || [],
    redirect: classa5nDilMd11Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/mission/class.vue").then(m => m.default || m)
  },
  {
    name: indexQmVFm4ZxVbMeta?.name ?? "mission",
    path: indexQmVFm4ZxVbMeta?.path ?? "/mission",
    meta: indexQmVFm4ZxVbMeta || {},
    alias: indexQmVFm4ZxVbMeta?.alias || [],
    redirect: indexQmVFm4ZxVbMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/mission/index.vue").then(m => m.default || m)
  },
  {
    name: normalEd3uVyKUiJMeta?.name ?? "mission-normal",
    path: normalEd3uVyKUiJMeta?.path ?? "/mission/normal",
    meta: normalEd3uVyKUiJMeta || {},
    alias: normalEd3uVyKUiJMeta?.alias || [],
    redirect: normalEd3uVyKUiJMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/mission/normal.vue").then(m => m.default || m)
  },
  {
    name: t_indexE1HLpwdoZ4Meta?.name ?? "mission-t_index",
    path: t_indexE1HLpwdoZ4Meta?.path ?? "/mission/t_index",
    meta: t_indexE1HLpwdoZ4Meta || {},
    alias: t_indexE1HLpwdoZ4Meta?.alias || [],
    redirect: t_indexE1HLpwdoZ4Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/mission/t_index.vue").then(m => m.default || m)
  },
  {
    name: _91page_name_93USuT48QWGkMeta?.name ?? "news-page_name",
    path: _91page_name_93USuT48QWGkMeta?.path ?? "/news/:page_name()",
    meta: _91page_name_93USuT48QWGkMeta || {},
    alias: _91page_name_93USuT48QWGkMeta?.alias || [],
    redirect: _91page_name_93USuT48QWGkMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/news/[page_name].vue").then(m => m.default || m)
  },
  {
    name: kuji202306cHRRyUHw0SMeta?.name ?? "news-kuji202306",
    path: kuji202306cHRRyUHw0SMeta?.path ?? "/news/kuji202306",
    meta: kuji202306cHRRyUHw0SMeta || {},
    alias: kuji202306cHRRyUHw0SMeta?.alias || [],
    redirect: kuji202306cHRRyUHw0SMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/news/kuji202306.vue").then(m => m.default || m)
  },
  {
    name: kuji202310sZ5nC1hQrlMeta?.name ?? "news-kuji202310",
    path: kuji202310sZ5nC1hQrlMeta?.path ?? "/news/kuji202310",
    meta: kuji202310sZ5nC1hQrlMeta || {},
    alias: kuji202310sZ5nC1hQrlMeta?.alias || [],
    redirect: kuji202310sZ5nC1hQrlMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/news/kuji202310.vue").then(m => m.default || m)
  },
  {
    name: indexS08n5kgKeIMeta?.name ?? "nft_connect",
    path: indexS08n5kgKeIMeta?.path ?? "/nft_connect",
    meta: indexS08n5kgKeIMeta || {},
    alias: indexS08n5kgKeIMeta?.alias || [],
    redirect: indexS08n5kgKeIMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/nft_connect/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_name_93O1Oc7wqTzAMeta?.name ?? "page-page_name",
    path: _91page_name_93O1Oc7wqTzAMeta?.path ?? "/page/:page_name()",
    meta: _91page_name_93O1Oc7wqTzAMeta || {},
    alias: _91page_name_93O1Oc7wqTzAMeta?.alias || [],
    redirect: _91page_name_93O1Oc7wqTzAMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/page/[page_name].vue").then(m => m.default || m)
  },
  {
    name: _91id_93cJP3jgfkwEMeta?.name ?? "panel_mission-id",
    path: _91id_93cJP3jgfkwEMeta?.path ?? "/panel_mission/:id()",
    meta: _91id_93cJP3jgfkwEMeta || {},
    alias: _91id_93cJP3jgfkwEMeta?.alias || [],
    redirect: _91id_93cJP3jgfkwEMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/panel_mission/[id].vue").then(m => m.default || m)
  },
  {
    name: _91_91page_93_93ZiDe2j6BiuMeta?.name ?? "present-page",
    path: _91_91page_93_93ZiDe2j6BiuMeta?.path ?? "/present/:page?",
    meta: _91_91page_93_93ZiDe2j6BiuMeta || {},
    alias: _91_91page_93_93ZiDe2j6BiuMeta?.alias || [],
    redirect: _91_91page_93_93ZiDe2j6BiuMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/present/[[page]].vue").then(m => m.default || m)
  },
  {
    name: index8vtKLiLD74Meta?.name ?? "privacy",
    path: index8vtKLiLD74Meta?.path ?? "/privacy",
    meta: index8vtKLiLD74Meta || {},
    alias: index8vtKLiLD74Meta?.alias || [],
    redirect: index8vtKLiLD74Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: backkTrWLmfO3WMeta?.name ?? "profile-back",
    path: backkTrWLmfO3WMeta?.path ?? "/profile/back",
    meta: backkTrWLmfO3WMeta || {},
    alias: backkTrWLmfO3WMeta?.alias || [],
    redirect: backkTrWLmfO3WMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/profile/back.vue").then(m => m.default || m)
  },
  {
    name: backgroundSSmzGfJJRUMeta?.name ?? "profile-background",
    path: backgroundSSmzGfJJRUMeta?.path ?? "/profile/background",
    meta: backgroundSSmzGfJJRUMeta || {},
    alias: backgroundSSmzGfJJRUMeta?.alias || [],
    redirect: backgroundSSmzGfJJRUMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/profile/background.vue").then(m => m.default || m)
  },
  {
    name: editp2BDw3yOloMeta?.name ?? "profile-edit",
    path: editp2BDw3yOloMeta?.path ?? "/profile/edit",
    meta: editp2BDw3yOloMeta || {},
    alias: editp2BDw3yOloMeta?.alias || [],
    redirect: editp2BDw3yOloMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: frontR7b2UdW4fLMeta?.name ?? "profile-front",
    path: frontR7b2UdW4fLMeta?.path ?? "/profile/front",
    meta: frontR7b2UdW4fLMeta || {},
    alias: frontR7b2UdW4fLMeta?.alias || [],
    redirect: frontR7b2UdW4fLMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/profile/front.vue").then(m => m.default || m)
  },
  {
    name: image_upladerY7Z7ds0uplMeta?.name ?? "profile-image_uplader",
    path: image_upladerY7Z7ds0uplMeta?.path ?? "/profile/image_uplader",
    meta: image_upladerY7Z7ds0uplMeta || {},
    alias: image_upladerY7Z7ds0uplMeta?.alias || [],
    redirect: image_upladerY7Z7ds0uplMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/profile/image_uplader.vue").then(m => m.default || m)
  },
  {
    name: indexCeOoCbOWxJMeta?.name ?? "profile",
    path: indexCeOoCbOWxJMeta?.path ?? "/profile",
    meta: indexCeOoCbOWxJMeta || {},
    alias: indexCeOoCbOWxJMeta?.alias || [],
    redirect: indexCeOoCbOWxJMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: titleUUtvEWaJVQMeta?.name ?? "profile-title",
    path: titleUUtvEWaJVQMeta?.path ?? "/profile/title",
    meta: titleUUtvEWaJVQMeta || {},
    alias: titleUUtvEWaJVQMeta?.alias || [],
    redirect: titleUUtvEWaJVQMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/profile/title.vue").then(m => m.default || m)
  },
  {
    name: indexMWxQTim6TBMeta?.name ?? "questionnaire",
    path: indexMWxQTim6TBMeta?.path ?? "/questionnaire",
    meta: indexMWxQTim6TBMeta || {},
    alias: indexMWxQTim6TBMeta?.alias || [],
    redirect: indexMWxQTim6TBMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/questionnaire/index.vue").then(m => m.default || m)
  },
  {
    name: indexufvoXuLcAHMeta?.name ?? "rarerank",
    path: indexufvoXuLcAHMeta?.path ?? "/rarerank",
    meta: indexufvoXuLcAHMeta || {},
    alias: indexufvoXuLcAHMeta?.alias || [],
    redirect: indexufvoXuLcAHMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/rarerank/index.vue").then(m => m.default || m)
  },
  {
    path: summaryWkDB3kVgPfMeta?.path ?? "/rarerank/summary",
    children: [
  {
    name: indexsmQ6k1UA8hMeta?.name ?? "rarerank-summary",
    path: indexsmQ6k1UA8hMeta?.path ?? "",
    meta: indexsmQ6k1UA8hMeta || {},
    alias: indexsmQ6k1UA8hMeta?.alias || [],
    redirect: indexsmQ6k1UA8hMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/rarerank/summary/index.vue").then(m => m.default || m)
  }
],
    name: summaryWkDB3kVgPfMeta?.name ?? undefined,
    meta: summaryWkDB3kVgPfMeta || {},
    alias: summaryWkDB3kVgPfMeta?.alias || [],
    redirect: summaryWkDB3kVgPfMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/rarerank/summary.vue").then(m => m.default || m)
  },
  {
    name: detail5dLbjFWGKTMeta?.name ?? "sales-detail",
    path: detail5dLbjFWGKTMeta?.path ?? "/sales/detail",
    meta: detail5dLbjFWGKTMeta || {},
    alias: detail5dLbjFWGKTMeta?.alias || [],
    redirect: detail5dLbjFWGKTMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/sales/detail.vue").then(m => m.default || m)
  },
  {
    name: listcmtQxV2UqvMeta?.name ?? "sales-list",
    path: listcmtQxV2UqvMeta?.path ?? "/sales/list",
    meta: listcmtQxV2UqvMeta || {},
    alias: listcmtQxV2UqvMeta?.alias || [],
    redirect: listcmtQxV2UqvMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/sales/list.vue").then(m => m.default || m)
  },
  {
    name: resultD56xVG4AJ0Meta?.name ?? "sales-result",
    path: resultD56xVG4AJ0Meta?.path ?? "/sales/result",
    meta: resultD56xVG4AJ0Meta || {},
    alias: resultD56xVG4AJ0Meta?.alias || [],
    redirect: resultD56xVG4AJ0Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/sales/result.vue").then(m => m.default || m)
  },
  {
    name: indexOqToVYMftaMeta?.name ?? "select_club",
    path: indexOqToVYMftaMeta?.path ?? "/select_club",
    meta: indexOqToVYMftaMeta || {},
    alias: indexOqToVYMftaMeta?.alias || [],
    redirect: indexOqToVYMftaMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/select_club/index.vue").then(m => m.default || m)
  },
  {
    name: indexXcL7W9VlWdMeta?.name ?? "serial",
    path: indexXcL7W9VlWdMeta?.path ?? "/serial",
    meta: indexXcL7W9VlWdMeta || {},
    alias: indexXcL7W9VlWdMeta?.alias || [],
    redirect: indexXcL7W9VlWdMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/serial/index.vue").then(m => m.default || m)
  },
  {
    name: indexbq38T9k0IXMeta?.name ?? "setting",
    path: indexbq38T9k0IXMeta?.path ?? "/setting",
    meta: indexbq38T9k0IXMeta || {},
    alias: indexbq38T9k0IXMeta?.alias || [],
    redirect: indexbq38T9k0IXMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexNO517MK1R4Meta?.name ?? "title",
    path: indexNO517MK1R4Meta?.path ?? "/title",
    meta: indexNO517MK1R4Meta || {},
    alias: indexNO517MK1R4Meta?.alias || [],
    redirect: indexNO517MK1R4Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/title/index.vue").then(m => m.default || m)
  },
  {
    name: indexpa8b3oOsBjMeta?.name ?? "trade",
    path: indexpa8b3oOsBjMeta?.path ?? "/trade",
    meta: indexpa8b3oOsBjMeta || {},
    alias: indexpa8b3oOsBjMeta?.alias || [],
    redirect: indexpa8b3oOsBjMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/trade/index.vue").then(m => m.default || m)
  },
  {
    name: indextLJrUsXvkGMeta?.name ?? "trophy",
    path: indextLJrUsXvkGMeta?.path ?? "/trophy",
    meta: indextLJrUsXvkGMeta || {},
    alias: indextLJrUsXvkGMeta?.alias || [],
    redirect: indextLJrUsXvkGMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/trophy/index.vue").then(m => m.default || m)
  },
  {
    name: memberXRxRgjw1a2Meta?.name ?? "trophy-member",
    path: memberXRxRgjw1a2Meta?.path ?? "/trophy/member",
    meta: memberXRxRgjw1a2Meta || {},
    alias: memberXRxRgjw1a2Meta?.alias || [],
    redirect: memberXRxRgjw1a2Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/trophy/member.vue").then(m => m.default || m)
  },
  {
    name: navi4tdoZ03RrVMeta?.name ?? "tutorial-navi",
    path: navi4tdoZ03RrVMeta?.path ?? "/tutorial/navi",
    meta: navi4tdoZ03RrVMeta || {},
    alias: navi4tdoZ03RrVMeta?.alias || [],
    redirect: navi4tdoZ03RrVMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/tutorial/navi.vue").then(m => m.default || m)
  },
  {
    name: act_on_settlement2KBGZrGmqIMeta?.name ?? "user_policy-act_on_settlement",
    path: act_on_settlement2KBGZrGmqIMeta?.path ?? "/user_policy/act_on_settlement",
    meta: act_on_settlement2KBGZrGmqIMeta || {},
    alias: act_on_settlement2KBGZrGmqIMeta?.alias || [],
    redirect: act_on_settlement2KBGZrGmqIMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/user_policy/act_on_settlement.vue").then(m => m.default || m)
  },
  {
    name: indexhQS6wXk0hKMeta?.name ?? "user_policy",
    path: indexhQS6wXk0hKMeta?.path ?? "/user_policy",
    meta: indexhQS6wXk0hKMeta || {},
    alias: indexhQS6wXk0hKMeta?.alias || [],
    redirect: indexhQS6wXk0hKMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/user_policy/index.vue").then(m => m.default || m)
  },
  {
    name: tokushoXiGuNb5jkzMeta?.name ?? "user_policy-tokusho",
    path: tokushoXiGuNb5jkzMeta?.path ?? "/user_policy/tokusho",
    meta: tokushoXiGuNb5jkzMeta || {},
    alias: tokushoXiGuNb5jkzMeta?.alias || [],
    redirect: tokushoXiGuNb5jkzMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/user_policy/tokusho.vue").then(m => m.default || m)
  },
  {
    name: transfer_confirm1WLOld6YQ1Meta?.name ?? "user-account-transfer_confirm",
    path: transfer_confirm1WLOld6YQ1Meta?.path ?? "/user/account/transfer_confirm",
    meta: transfer_confirm1WLOld6YQ1Meta || {},
    alias: transfer_confirm1WLOld6YQ1Meta?.alias || [],
    redirect: transfer_confirm1WLOld6YQ1Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/user/account/transfer_confirm.vue").then(m => m.default || m)
  },
  {
    name: transferLzwxcSKyx9Meta?.name ?? "user-account-transfer",
    path: transferLzwxcSKyx9Meta?.path ?? "/user/account/transfer",
    meta: transferLzwxcSKyx9Meta || {},
    alias: transferLzwxcSKyx9Meta?.alias || [],
    redirect: transferLzwxcSKyx9Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/user/account/transfer.vue").then(m => m.default || m)
  },
  {
    name: crystal_purchasefYY1tj9l9ZMeta?.name ?? "user-crystal_purchase",
    path: crystal_purchasefYY1tj9l9ZMeta?.path ?? "/user/crystal_purchase",
    meta: crystal_purchasefYY1tj9l9ZMeta || {},
    alias: crystal_purchasefYY1tj9l9ZMeta?.alias || [],
    redirect: crystal_purchasefYY1tj9l9ZMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/user/crystal_purchase.vue").then(m => m.default || m)
  },
  {
    name: serialtKcj4nrB6sMeta?.name ?? "user-input-serial",
    path: serialtKcj4nrB6sMeta?.path ?? "/user/input/serial",
    meta: serialtKcj4nrB6sMeta || {},
    alias: serialtKcj4nrB6sMeta?.alias || [],
    redirect: serialtKcj4nrB6sMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/user/input/serial.vue").then(m => m.default || m)
  },
  {
    name: indexFfpTCqoZycMeta?.name ?? "user-login",
    path: indexFfpTCqoZycMeta?.path ?? "/user/login",
    meta: indexFfpTCqoZycMeta || {},
    alias: indexFfpTCqoZycMeta?.alias || [],
    redirect: indexFfpTCqoZycMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/user/login/index.vue").then(m => m.default || m)
  },
  {
    name: loginbytidKq8n7ICGJgMeta?.name ?? "user-login-loginbytid",
    path: loginbytidKq8n7ICGJgMeta?.path ?? "/user/login/loginbytid",
    meta: loginbytidKq8n7ICGJgMeta || {},
    alias: loginbytidKq8n7ICGJgMeta?.alias || [],
    redirect: loginbytidKq8n7ICGJgMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/user/login/loginbytid.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93niCxswdaELMeta?.name ?? "user-mypage-uid",
    path: _91uid_93niCxswdaELMeta?.path ?? "/user/mypage/:uid()",
    meta: _91uid_93niCxswdaELMeta || {},
    alias: _91uid_93niCxswdaELMeta?.alias || [],
    redirect: _91uid_93niCxswdaELMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/user/mypage/[uid].vue").then(m => m.default || m)
  },
  {
    name: controllerZiuoGjhPoCMeta?.name ?? "user-mypage-controller",
    path: controllerZiuoGjhPoCMeta?.path ?? "/user/mypage/controller",
    meta: controllerZiuoGjhPoCMeta || {},
    alias: controllerZiuoGjhPoCMeta?.alias || [],
    redirect: controllerZiuoGjhPoCMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/user/mypage/controller.js").then(m => m.default || m)
  },
  {
    name: pointhistory4RUyZhJfNRMeta?.name ?? "user-pointhistory",
    path: pointhistory4RUyZhJfNRMeta?.path ?? "/user/pointhistory",
    meta: pointhistory4RUyZhJfNRMeta || {},
    alias: pointhistory4RUyZhJfNRMeta?.alias || [],
    redirect: pointhistory4RUyZhJfNRMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/user/pointhistory.vue").then(m => m.default || m)
  },
  {
    name: preview_cards_tmpMhymxY5kfbMeta?.name ?? "user-preview_cards_tmp",
    path: preview_cards_tmpMhymxY5kfbMeta?.path ?? "/user/preview_cards_tmp",
    meta: preview_cards_tmpMhymxY5kfbMeta || {},
    alias: preview_cards_tmpMhymxY5kfbMeta?.alias || [],
    redirect: preview_cards_tmpMhymxY5kfbMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/user/preview_cards_tmp.vue").then(m => m.default || m)
  },
  {
    name: search_uid0iIZZTK1I8Meta?.name ?? "user-search_uid",
    path: search_uid0iIZZTK1I8Meta?.path ?? "/user/search_uid",
    meta: search_uid0iIZZTK1I8Meta || {},
    alias: search_uid0iIZZTK1I8Meta?.alias || [],
    redirect: search_uid0iIZZTK1I8Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/user/search_uid.vue").then(m => m.default || m)
  },
  {
    name: allstarhw1qEyatByMeta?.name ?? "vote-allstar",
    path: allstarhw1qEyatByMeta?.path ?? "/vote/allstar",
    meta: allstarhw1qEyatByMeta || {},
    alias: allstarhw1qEyatByMeta?.alias || [],
    redirect: allstarhw1qEyatByMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/vote/allstar.vue").then(m => m.default || m)
  },
  {
    name: allstar20215jgQjyoh32Meta?.name ?? "vote-allstar2021",
    path: allstar20215jgQjyoh32Meta?.path ?? "/vote/allstar2021",
    meta: allstar20215jgQjyoh32Meta || {},
    alias: allstar20215jgQjyoh32Meta?.alias || [],
    redirect: allstar20215jgQjyoh32Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/vote/allstar2021.vue").then(m => m.default || m)
  },
  {
    name: altairyUF53J00fhMeta?.name ?? "vote-altair",
    path: altairyUF53J00fhMeta?.path ?? "/vote/altair",
    meta: altairyUF53J00fhMeta || {},
    alias: altairyUF53J00fhMeta?.alias || [],
    redirect: altairyUF53J00fhMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/vote/altair.vue").then(m => m.default || m)
  },
  {
    name: card_serieszsi7on7YXMMeta?.name ?? "vote-card_series",
    path: card_serieszsi7on7YXMMeta?.path ?? "/vote/card_series",
    meta: card_serieszsi7on7YXMMeta || {},
    alias: card_serieszsi7on7YXMMeta?.alias || [],
    redirect: card_serieszsi7on7YXMMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/vote/card_series.vue").then(m => m.default || m)
  },
  {
    name: _91deckId_93Dm4nWGcXf4Meta?.name ?? "vote-dreamteam-deckId",
    path: _91deckId_93Dm4nWGcXf4Meta?.path ?? "/vote/dreamteam/:deckId()",
    meta: _91deckId_93Dm4nWGcXf4Meta || {},
    alias: _91deckId_93Dm4nWGcXf4Meta?.alias || [],
    redirect: _91deckId_93Dm4nWGcXf4Meta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/vote/dreamteam/[deckId].vue").then(m => m.default || m)
  },
  {
    name: indexnJMCJgHp1SMeta?.name ?? "vote",
    path: indexnJMCJgHp1SMeta?.path ?? "/vote",
    meta: indexnJMCJgHp1SMeta || {},
    alias: indexnJMCJgHp1SMeta?.alias || [],
    redirect: indexnJMCJgHp1SMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/vote/index.vue").then(m => m.default || m)
  },
  {
    name: mascoto9Wuo1OEqBMeta?.name ?? "vote-mascot",
    path: mascoto9Wuo1OEqBMeta?.path ?? "/vote/mascot",
    meta: mascoto9Wuo1OEqBMeta || {},
    alias: mascoto9Wuo1OEqBMeta?.alias || [],
    redirect: mascoto9Wuo1OEqBMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/vote/mascot.vue").then(m => m.default || m)
  },
  {
    name: valentineEVIX7OKVGKMeta?.name ?? "vote-valentine",
    path: valentineEVIX7OKVGKMeta?.path ?? "/vote/valentine",
    meta: valentineEVIX7OKVGKMeta || {},
    alias: valentineEVIX7OKVGKMeta?.alias || [],
    redirect: valentineEVIX7OKVGKMeta?.redirect || undefined,
    component: () => import("/var/emtg/card.bleaguepark.jp/pages/vote/valentine.vue").then(m => m.default || m)
  }
]