/**
 * @todo DA06003-105 executeだと何をするのかがわからないため、ベターな命名をしたい
 * @todo DA06003-105 例外処理を定義する
 */
export default defineNuxtPlugin(nuxtApp => {
  return {
    provide: {
      execute: (method, param) => {
        if (method = 'set_my_club') {
          try {
            $.ajax({
              url: SNS.getPath() + '/ajax_notice_set_my_club',
              type: 'POST',
              data: { param },
              cache: false,
              timeout: 10000,
              success: function (data, dataType) {
                location.reload();
              },
            });
          } catch(e) {}
        }
      }
    }
  }
})
